<template>
  <div class="page">
    <div class="content" style="flex-direction: column">
      <div class="about">
        <div class="img"><img
                :src="info.tx"></div>
        <div class="text">
          <h3>个人资料</h3>
          <dl>
            <dt>姓名</dt>
            <dd>{{info.username}}</dd>
          </dl>
          <dl>
            <dt>网名</dt>
            <dd>{{info.webname}}</dd>
          </dl>
          <dl>
            <dt>英文名</dt>
            <dd>{{info.enname}}</dd>
          </dl>
          <dl>
            <dt>生日</dt>
            <dd>{{info.birthday}}</dd>
          </dl>
          <dl>
            <dt>地址</dt>
            <dd>{{info.address}}</dd>
          </dl>
          <dl>
            <dt>毕业</dt>
            <dd>{{info.school}}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
    name: 'articles',
    data() {
        return {
            info: {}
        }
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        if (this.$store.state.articles.about.length>0) {
            this.info = this.$store.state.articles.about[0]
        } else {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'about'
            }
            this.$store.dispatch('articles/getArticles', data).then((rs) => {
                this.info = rs[0]
            })

        }
    },
    mounted() {
    },
    methods: {},
    computed: {}
}
</script>

